import React from 'react';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import achat from './buy';
import Data from './data/product.json';
import { Link, Route } from 'react-router-dom';
import { Container, Row } from 'react-bootstrap';


var err = "Please scroll down";

const Product = (props) =>{
    return(
        <>
      <Container fluid style={{marginLeft:"100px"}}>
        <Row>
  {Data.map((e) =>(
    <Card style={{ width: '18rem'}}>
    <Card.Img variant="top" src={e.url} style={{height:"286px", width:"286px"}}/>
    <Card.Body>
    <Card.Title>{e.name}</Card.Title>
    <Card.Subtitle className="text-muted">{e.price}€</Card.Subtitle>
    <br/>
      <Button variant="primary" onClick={a =>{alert(err)}}><Link to="/Product/buy" style={{color: "white", textDecoration: "none"}}>Buy</Link></Button>
    </Card.Body>
  </Card>
  ))}

    <Route path="/Product/buy" exact component={achat}></Route>
        </Row>
    </Container>
        </>
    )
}
export default Product