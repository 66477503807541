import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Data from './data/product.json';

const achat = (props) =>{
    return(
        <>
           <Container>
               <Row>
                   <Col>
                   <div style={{padding: "3.5cm", backgroundColor: "white", borderRadius: "20px", border:"2px outset #26EDF9"}}>
                    <h3 style={{textAlign:"center", marginTop: "-50px"}}>Demande de Livraison</h3>
                    <form style={{display:"block"}} name="achat">
                            <input type="hidden" name="form-name" value="achat"/>
                            <br/>
                            <p>
                        <label>Nom<span style={{color: "red"}}>*</span><br/><input type="text" name="nom" required/></label>
                            </p>
                            <p>
                        <label>E-mail<span style={{color: "red"}}>*</span><br/><input type="email" name="email" required/></label>
                            </p>
                            <p>
                        <label>Nom du produit<span style={{color: "red"}}>*</span><br/>
                                <select>
                                {Data.map((e) =>(<option>{e.name}</option>))}       
                                </select>
                        </label>
                            </p>
                            <p>
                            <button type="submit">Envoyer</button>
                            </p>
                    </form>
                   </div>
                   </Col>
               </Row>
           </Container>
        </>
    )
};

export default achat;
